<!-- 特色馆藏 -->
<template>
  <div class="characteristic publicPage">
    <div class="content">
      <ititle :text="firstTitle" :more="true" @moreClick="moreClick"></ititle>
      <ul>
        <li v-for="card in cardList" :key="card.name" @click="toSecend(card)">
          <img :src="card.src" alt="" />
          <span>{{ card.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import { getFirstTitle, getArticle } from "../../utils/commonApi";
import { toSecend } from "../../utils/util";
export default {
  components: { ititle },
  data() {
    return {
      firstTitle: "",
      cardList: [
        {
          name: "曾文正公批牍",
          src: "",
        },
        {
          name: "杜少陵全集笺注",
          src: "",
        },
        {
          name: "贵州下文",
          src: "",
        },
        {
          name: "钦定文献通考",
          src: "",
        },
        {
          name: "四库全书",
          src: "",
        },
        {
          name: "周礼",
          src: "",
        },
        {
          name: "金石大字典",
          src: "",
        },
        {
          name: "六后天是实价周浦",
          src: "",
        },
        {
          name: "石碑",
          src: "",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //去往二级标题
    moreClick() {
      //跳转至二级首页
      let params = {
        firstId: 5,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
    toSecend(card) {
      //跳转至二级首页
      let params = {
        firstId: 5,
        secondId: card.id,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
  },
  created() {},
  mounted() {
    //获取列表
    getFirstTitle(5, (res) => {
      let children = res.children;
      this.firstTitle = res.title;
      this.cardList.forEach((item, i) => {
        item.name = children[i].title;
        if (children[i].img) {
          item.src = children[i].img;
        }
        item.id = children[i].id;
      });
    });
  },
};
</script>

<style lang="less" scoped>
.characteristic {
  .content {
    width: 1100px;
    display: flex;
    flex-direction: column;
    /deep/ .ititle {
      margin: 30px 0px;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 32%;
        font-size: 16px;
        cursor: pointer;
        margin-bottom: 20px;
        img {
          margin-bottom: 10px;
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .characteristic {
      padding: 0px 10px;
      box-sizing: border-box;
      .content {
        width: 100%;
        ul {
          li {
            font-size: 12px;
            img {
              height: 100px;
            }
          }
        }
      }
    }
  }
}
</style>
