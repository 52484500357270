<!-- 活动中心 -->
<template>
  <div class="activity publicPage">
    <div class="content">
      <div class="left">
        <ititle text="活动动态" :moreTwo="true" @moreClick="moreClick(6)"></ititle>
        <div class="box">
          <div class="boxLeft" @click="toLeftThree(artInfo.id)">
            <img :src="artInfo.img" alt="" />
            <div class="time">{{ timefmt(artInfo.write_time) }}</div>
            <div class="title">{{ artInfo.title }}</div>
            <div class="text">
              <span v-html="artInfo.content"></span>
            </div>
            <span style="color: #f00">[详情>>]</span>
          </div>
          <div class="boxRight">
            <slider animation="fade" height="100%" width="100%" :control-btn="false">
              <slider-item v-for="(i, index) in arts" :key="index">
                <div
                  class="articTab"
                  v-for="art in i.artList"
                  :key="art.id"
                  @click="toLeftThree(art.id)"
                  @mouseenter="artEnter(art)"
                >
                  <div class="time">
                    <span class="day">{{ getDay(art.write_time) }}</span>
                    <span class="date">{{ getMonth(art.write_time) }}</span>
                  </div>
                  <div class="atrTitle">
                    {{ art.title }}
                  </div>
                </div>
              </slider-item>
            </slider>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="rightHead">
          <div
            v-for="head in headList"
            :key="head.id"
            class="head"
            @click="headClick(head)"
            :class="{ active: rightActive == head.id }"
          >
            {{ head.name }}
          </div>
          <div class="head more" @click="moreClick(rightActive)">MORE+</div>
        </div>
        <div class="rightList">
          <slider animation="fade" height="100%" width="100%" :control-btn="false">
            <slider-item v-for="(i, index) in list" :key="index">
              <div class="item" v-for="item in i.list" :key="item.id" @click="toThree(item)">
                <span class="dage"></span>
                <span class="itemTitle">{{ item.title }}</span>
                <span class="date">{{ timefmt(item.write_time) }}</span>
              </div>
            </slider-item>
          </slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import { Slider, SliderItem } from "vue-easy-slider";
import { getFirstTitle, getArticle } from "../../utils/commonApi";
import dayjs from "dayjs";
import { get_title_article } from "../../request/apis";
import { cloneDeep } from "lodash";
export default {
  components: { ititle, Slider, SliderItem },
  data() {
    return {
      artInfo: {
        img: "",
        date: "",
        title: "",
        content: "",
      },
      rightId: "",
      lelftId: "",
      arts: [],
      firstTitle: "活动动态",
      headList: [
        {
          name: "通知公告",
          id: 7,
        },
        {
          name: "资源动态",
          id: 8,
        },
        {
          name: "本馆新闻",
          id: 9,
        },
      ],
      rightActive: 7,
      rightActiveName: "",
      list: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    headClick(head) {
      this.rightActive = head.id;
      this.rightActiveName = head.name;
      getFirstTitle(head.id, (res) => {
        this.rightId = res.children[0].id;
        get_title_article(res.children[0].id).then((data) => {
          this.list = [
            {
              list: [],
            },
          ];
          data.sort((a, b) => new Date(b.write_time).getTime() - new Date(a.write_time).getTime());
          data.forEach((v, i) => {
            if (i % 7 == 0 && i !== 0) {
              this.list.push({
                list: [v],
              });
            } else {
              this.list[this.list.length - 1].list.push(v);
            }
          });
        });
      });
    },
    //更多
    moreClick(firstId, secondId) {
      //跳转至二级首页
      let params = {
        firstId: firstId,
        secondId: secondId,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
    //跳转到三级页面
    toThree(item) {
      this.$router.push({
        path: "/threePage",
        query: {
          pageId: item.id,
          address: ["当前位置:首页", this.rightActiveName, this.rightActiveName],
        },
      });
    },
    //鼠标滑动
    artEnter(art) {
      debugger;
      this.artInfo = art;
    },
    toLeftThree(id) {
      this.$router.push({
        path: "/threePage",
        query: {
          pageId: id,
          address: ["当前位置:首页", this.firstTitle, this.firstTitle],
        },
      });
    },
    //时间格式化
    timefmt(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    getDay(date) {
      return dayjs(date).format("DD");
    },
    getMonth(date) {
      return dayjs(date).format("YYYY-MM");
    },
  },
  created() {},
  mounted() {
    //获取左侧一级标题
    getFirstTitle(6, (res) => {
      this.firstTitle = res.title;
      this.lelftId = res.children[0].id;
      get_title_article(res.children[0].id).then((data) => {
        this.artList = [
          {
            artList: [],
          },
        ];
        this.arts = [];
        this.artInfo = cloneDeep(data)[0];
        data.sort((a, b) => new Date(b.write_time).getTime() - new Date(a.write_time).getTime());
        data.forEach((v, i) => {
          if (i != 0) {
            let index = i - 1;
            if (index % 4 == 0) {
              this.arts.push({
                artList: [v],
              });
            } else {
              this.arts[this.arts.length - 1].artList.push(v);
            }
          }
        });
      });
    });
    this.headClick({
      id: 7,
      name: "通知公告",
    });
  },
};
</script>
<style lang="less" scoped>
.activity {
  background-color: #e7e5e6;
  height: 585px;
  box-sizing: border-box;
  padding: 30px 0px;
  .content {
    width: 1100px;
    display: flex;
    .left {
      width: 55%;
      background-color: #fff;
      border-radius: 10px;
      margin-right: 20px;
      padding: 10px 30px;
      /deep/ .ititle {
        padding-left: 0px;
      }
      .box {
        display: flex;
        .boxLeft,
        .boxRight {
          flex: 1;
          height: 440px;
        }
        .boxLeft {
          width: 300px;
          img {
            width: 100%;
            height: 160px;
          }
          .time,
          .title {
            margin: 10px 0px;
          }
          .time {
            color: #cca332;
            font-weight: bold;
          }
          .title {
            font-size: 18px;
          }
          .text {
            height: 70px;
            overflow: hidden;
          }
        }
        .boxRight {
          margin-left: 10px;
          .articTab {
            display: flex;
            padding: 20px 0px;
            border-bottom: 1px solid #eee;
            align-items: center;
            cursor: pointer;
            .time {
              display: flex;
              flex-direction: column;
              align-items: center;
              color: #cca332;
              flex: 0 0 80px;
              .day {
                font-size: 20px;
              }
              .date {
                padding-top: 6px;
                border-top: 1px solid #cca332;
                margin-top: 10px;
              }
            }
            .atrTitle {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              line-height: 1.4;
              height: 42px;
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .rightHead {
        height: 62px;
        display: flex;
        .head {
          font-size: 18px;
          font-weight: bolder;
          display: flex;
          align-items: center;
          width: 25%;
          justify-content: center;
          cursor: pointer;
          color: rgb(48, 47, 47);
        }
        .active {
          background-color: #cca332;
          color: #fff;
        }
        .more {
          font-size: 16px;
          font-weight: normal;
          color: #cca332;
        }
      }
      .rightList {
        flex: 1;
        overflow-y: auto;
        .item {
          border-bottom: 1px solid rgb(209, 208, 208);
          padding: 20px 0px;
          margin: 0px 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          .dage {
            margin-right: 6px;
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: rgb(63, 63, 63);
            border-radius: 50%;
          }
          .itemTitle {
            max-width: 200px;
            line-height: 1.5;
          }
          .date {
            margin-left: auto;
            color: #cca332;
          }
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .activity {
      background-color: #fff;
      height: auto;
      .content {
        flex-direction: column;
        .left {
          width: auto;
          padding: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
