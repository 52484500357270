<!-- 部门设置 -->
<template>
  <div class="bg">
    <slider animation="fade" :height="height" width="100%" :control-btn="false">
      <slider-item v-for="(i, index) in imgList" :key="index">
        <img :src="i.src" alt="" />
      </slider-item>
    </slider>
    <div class="bgContent">
      <div class="btnBox">
        <span @click="type = 0" :class="{ btnactive: type == 0 }">书目检索</span>
        <span @click="type = 1" :class="{ btnactive: type == 1 }">站内检索</span>
      </div>
      <div class="searchBox">
        <el-input placeholder="请输入关键字搜索" v-model="search">
          <el-select v-model="select" slot="prepend" placeholder="请选择">
            <template v-for="item in searchList">
              <el-option
                :label="item.label"
                :value="item.val"
                :key="item.val"
                v-if="item.flag == type || !item.flag"
              ></el-option>
            </template>
          </el-select>
        </el-input>
        <span class="btn" @click="goSearch('zh')">搜索</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
import img1 from "../../assets/imgs/1.jpg";
import img2 from "../../assets/imgs/2.jpg";
import { toSecend, toPage } from "../../utils/util";
import { search_key, search_flag, search_book, book_flag } from "../../request/apis";
export default {
  props: ["height"],
  components: { Slider, SliderItem },
  data() {
    return {
      imgList: [{ src: img1 }, { src: img2 }],
      search: "",
      type: 1,
      select: "all",
      searchList: [
        {
          label: "书名/刊名",
          val: "title",
          flag: "0",
        },
        {
          label: "书名",
          val: "title",
          flag: "1",
        },
        {
          label: "ISBN号",
          val: "isbn",
          flag: "0",
        },
        {
          label: "作者",
          val: "writer",
        },
        {
          label: "索书号",
          val: "callno",
          flag: "0",
        },
        {
          label: "出版社",
          val: "publisher",
          flag: "0",
        },
        {
          label: "出版时间",
          val: "publish_time",
          flag: "0",
        },
        {
          label: "所有字段",
          val: "all",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    goSearch() {
      let http;
      let params;
      if (this.type == 1) {
        //站内搜索
        if (this.select == "all") {
          //全部字段
          http = search_key;
          params = this.search;
        } else {
          http = search_flag;
          params = {
            [this.select]: this.search,
          };
        }
      }
      if (this.type == 0) {
        //书目搜索
        if (this.select == "all") {
          //全部字段
          http = search_book;
          params = this.search;
        } else {
          http = book_flag;
          params = {
            [this.select]: this.search,
          };
        }
      }
      http(params).then((res) => {
        sessionStorage.setItem("searchList", JSON.stringify(res));
        this.$router.push("/searchPage");
      });
    },
  },
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.bg {
  width: 100vw;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  .slider-item {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bgContent {
    width: 1100px;
    position: absolute;
    height: 200px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    box-sizing: border-box;
    padding: 20px 60px;
    .btnBox {
      width: 100%;
      margin-bottom: 20px;
      span {
        padding: 8px 20px;
        border-radius: 20px;
        background-color: #666;
        color: #fff;
        font-size: 16px;
        margin-right: 20px;
        display: inline-block;
        cursor: pointer;
      }
      .btnactive {
        background-color: rgb(174, 0, 1);
        color: #fff;
      }
    }
    /deep/ .searchBox {
      width: 100%;
      display: flex;
      align-items: center;
      .btn {
        display: inline-block;
        height: 39px;
        line-height: 39px;
        text-align: center;
        background-color: rgb(174, 0, 1);
        border-radius: 6px;
        width: 110px;
        color: #fff;
        font-size: 16px;
        margin-left: 10px;
        cursor: pointer;
      }
      // .el-input {
      //   flex: 1;
      // }
      // .el-input__inner {
      //   border: 2px solid #999;
      //   border-radius: 10px;
      //   height: 46px;
      //   padding-left: 40px;
      // }
      // .el-icon-search {
      //   font-size: 18px;
      //   color: #999;
      // }
      .el-select .el-input {
        width: 120px;
      }
    }
    /deep/ .radioBox {
      width: 100%;
      margin-top: 20px;
      .el-radio__label {
        font-size: 16px;
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .bg {
      .bgContent {
        width: 90%;
        padding: 10px 20px;
        height: 150px;
        .btnBox {
          span {
            font-size: 12px;
            padding: 4px 8px;
          }
        }
        .searchBox {
          .btn {
            width: 60px;
            height: 30px;
            line-height: 30px;
            font-size: 12px;
          }
          /deep/ .el-input__inner {
            height: 30px;
          }
          /deep/ .el-input__icon {
            display: flex;
            align-items: center;
          }
        }
        .radioBox {
          /deep/ .el-radio__label {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
