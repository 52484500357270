<!-- 数字图书馆 -->
<template>
  <div class="digLibrary publicPage">
    <div class="content">
      <ititle :text="firstTitle"></ititle>
      <div class="card">
        <div class="cardleft">
          <slider animation="fade" height="350px" :control-btn="false">
            <slider-item v-for="(slid, index) in slidList" :key="index" @click="goPage(slid)">
              <div class="slide">
                <img :src="slid.img" alt="" />
                <div class="conBox">
                  <div class="title">{{ slid.title }}</div>
                  <div class="pages">
                    <span v-html="slid.content" class="phtml"></span>
                    <span style="color: #f00">[详情>>]</span>
                  </div>
                </div>
              </div>
            </slider-item>
          </slider>
        </div>
        <div class="cardright">
          <ul>
            <li
              v-for="card in cardList"
              :key="card.id"
              :style="{ backgroundColor: card.color }"
              @click="goSecend(card)"
            >
              <img :src="card.src" alt="" />
              <span>{{ card.name }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import { Slider, SliderItem } from "vue-easy-slider";
import img1 from "../../assets/3-数字图书馆/3-电子期刊.png";
import img2 from "../../assets/3-数字图书馆/4-电子图书.png";
import img3 from "../../assets/3-数字图书馆/5-古籍数据库.png";
import img4 from "../../assets/3-数字图书馆/6-经济数据库.png";
import img5 from "../../assets/3-数字图书馆/7-多媒体学习库.png";
import img6 from "../../assets/3-数字图书馆/8-少儿数据库.png";
import img7 from "../../assets/3-数字图书馆/9-自建资源.png";
import { getFirstTitle, getArticle } from "../../utils/commonApi";
import { get_title_article } from "../../request/apis";
import { toSecend, toPage } from "../../utils/util";
export default {
  components: { ititle, Slider, SliderItem },
  data() {
    return {
      slidList: [],
      firstTitle: "",
      cardList: [
        {
          name: "电子期刊",
          color: "#8dc153",
          src: img1,
        },
        {
          name: "电子图书",
          color: "#ec87c1",
          src: img2,
        },
        {
          name: "古籍数据库",
          color: "#37bd9c",
          src: img3,
        },
        {
          name: "经济数据库",
          color: "#3baeda",
          src: img1,
        },
        {
          name: "多媒体数据库",
          color: "#59aa34",
          src: img5,
        },
        {
          name: "少儿数据库",
          color: "#fb6e52",
          src: img6,
        },
        {
          name: "自建资源",
          color: "#967bdc",
          src: img7,
        },
        {
          name: "试用数据库",
          color: "#f9b552",
          src: img4,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //去往二级标题
    goSecend(card) {
      //跳转至二级首页
      let params = {
        firstId: 2,
        secondId: card.id,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
    //去往文章详情
    goPage(card) {
      //跳转至二级首页
      let params = {
        firstId: 2,
        secondId: this.secondId,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
  },
  created() {},
  mounted() {
    //获取二级标题
    getFirstTitle(2, (res) => {
      let children = res.children;
      this.firstTitle = res.title;
      this.cardList.forEach((item, i) => {
        this.secondId = res.children[0].id;
        item.name = children[i + 1].title;
        item.id = children[i + 1].id;
        if (children[i + 1].img) {
          item.src = children[i + 1].img;
        }
      });
      get_title_article(res.children[0].id).then((res) => {
        this.slidList = res;
      });
      //获取2篇文章
    });
  },
};
</script>
<style lang="less" scoped>
.digLibrary {
  margin-top: 40px;
  padding-bottom: 40px;
  .content {
    width: 1100px;
    .card {
      display: flex;
      margin-top: 40px;
      .cardleft {
        width: 380px;
        margin-right: 20px;
        .slide {
          width: 100%;
          height: 100%;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .conBox {
            position: absolute;
            height: 50%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            color: #000;
            bottom: 0px;
            box-sizing: border-box;
            padding: 0px 4px;
            .title {
              text-align: center;
              padding: 6px 0px;
              font-size: 14px;
              font-weight: bold;
            }
            .pages {
              word-wrap: break-word;
              word-break: keep-all;

              .phtml {
                height: 80px;
                overflow: hidden;
                display: inline-block;
              }
            }
          }
        }
      }
      .cardright {
        flex: 1;
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 24%;
            color: #fff;
            height: 170px;
            margin-bottom: 10px;
            font-size: 16px;
            cursor: pointer;
            img {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .digLibrary {
      .content {
        width: 100%;
        .card {
          margin-top: 10px;
          flex-direction: column;
          .cardleft {
            width: 100%;
            margin-right: 0px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>
