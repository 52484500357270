import {first_title,get_article} from '../request/apis';
//获取一级标题
export function getFirstTitle(id,cb){
    first_title(id).then((res)=>{
        cb(res);
    })
}
//获取文章
export function getArticle(id,cb){
    get_article(id).then((res)=>{
        cb(res);
    })
}