<!-- 本馆概括 -->
<template>
  <div class="wraparound publicPage">
    <div class="content">
      <div class="left warpBg" @click="toPage(3, second)">
        <ititle :text="firstTitle1"></ititle>
        <img :src="info.img" alt="" />
        <div class="pageContBox" >
          <span v-html="info.content"></span>
          <span style='color:#f00'>[详情>>]</span>
        </div>
        
      </div>
      <div class="right warpBg">
        <ititle :text="firstTitle2"></ititle>
        <ul>
          <li v-for="card in cardList" :key="card.name" @click="toPage(4, card)">
            <img :src="card.src" alt="" />
            <span>{{ card.name }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import img1 from "../../assets/4-本馆概括+读者服务/4-开放时间.png";
import img2 from "../../assets/4-本馆概括+读者服务/5-交通指南.png";
import img3 from "../../assets/4-本馆概括+读者服务/6-办证须知.png";
import img4 from "../../assets/4-本馆概括+读者服务/7-借阅规则.png";
import img5 from "../../assets/4-本馆概括+读者服务/8-书刊损坏.png";
import img6 from "../../assets/4-本馆概括+读者服务/9-退证须知.png";
import img7 from "../../assets/4-本馆概括+读者服务/10-文明服务.png";
import img8 from "../../assets/4-本馆概括+读者服务/11-年度报告.png";
import img9 from "../../assets/4-本馆概括+读者服务/12-发展规划.png";
import { getFirstTitle, getArticle } from "../../utils/commonApi";
import { get_title_article } from "../../request/apis";
export default {
  components: { ititle },
  data() {
    return {
      info: {
        img: "",
        des: "",
      },
      firstTitle1: "",
      firstTitle2: "",
      cardList: [
        {
          name: "开放时间",
          src: img1,
        },
        {
          name: "交通指南",
          src: img2,
        },
        {
          name: "办证须知",
          src: img3,
        },
        {
          name: "借阅规则",
          src: img4,
        },
        {
          name: "书刊损坏",
          src: img5,
        },
        {
          name: "退证须知",
          src: img6,
        },
        {
          name: "文明服务",
          src: img7,
        },
        {
          name: "年度报告",
          src: img8,
        },
        {
          name: "发展规划",
          src: img9,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //去往二级标题
    toPage(firstId, card) {
      //跳转至二级首页
      let params = {
        firstId: firstId,
        secondId: card.id,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
  },
  created() {},
  mounted() {
    getFirstTitle(3, (res) => {
      this.firstTitle1 = res.title;
      this.second = res.children[0];
      get_title_article(res.children[0].id).then((res) => {
        this.info = res[0];
        let reg = /<img.*?(?:>|\/>)/gi;
        this.info.content = this.info.content.replace(reg, "")+'...';
      });
    });
    //获取列表
    getFirstTitle(4, (res) => {
      this.firstTitle2 = res.title;
      let children = res.children;
      this.cardList.forEach((item, i) => {
        item.name = children[i].title;
        item.id = children[i].id;
        if (children[i].img) {
          item.src = children[i].img;
        }
      });
    });
  },
};
</script>
<style lang="less" scoped>
.wraparound {
  height: 750px;
  background-color: #e7e5e6;
  box-sizing: border-box;
  padding: 40px 0px;
  .content {
    width: 1100px;
    display: flex;
    height: 100%;
    .warpBg {
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 10px;
      cursor: pointer;
    }
    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
        height: 200px;
      }
      .pageContBox {
        height: calc(100% - 342px);
        /deep/ span{
          font-size: 16px !important;
        }
      }
    }
    .right {
      margin-left: 10px;
      flex: 1;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: calc(100% - 60px);
        li {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 32%;
          height: 33%;
          font-size: 16px;
          cursor: pointer;
          border: 1px solid #999;
          img {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .wraparound {
      height: auto;
      background-color: #fff;
      .content {
        flex-direction: column;
        width: 100%;
        .left {
          width: 100%;
          margin-bottom: 10px;
          height: 400px;
          img {
            height: 100px;
          }
        }
        .right {
          margin-left: 0px;
          ul {
            li {
              padding-bottom: 10px;
              img {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
