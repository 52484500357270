<!-- 底部链接 -->
<template>
  <div class="footer publicPage">
    <div class="content">
      <div class="topIcon">
        <span class="text">友情链接</span>
        <div class="icons">
          <div v-for="item in popoverList" :key="item.id" class="icon">
            <el-popover placement="top-start" title="" trigger="hover">
              <img :src="item.img" alt="" />
              <img :src="item.icon" alt="" slot="reference" />
            </el-popover>
          </div>
        </div>
      </div>
      <div class="link">
        <span v-for="link in linkList" :key="link.name">{{ link.name }}</span>
      </div>
      <div class="link info">
        <span v-for="info in infoList" :key="info.name">{{ info.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from "../../assets/9-友情链接/7-微信.png";
import icon2 from "../../assets/9-友情链接/10-微博.png";
import icon3 from "../../assets/9-友情链接/11-手机.png";
import img1 from "../../assets/9-友情链接/5-扫一扫 关注微信公众号.png";
import img2 from "../../assets/9-友情链接/6-贵州市图书馆   微信公众号.png";
export default {
  components: {},
  data() {
    return {
      linkList: [
        {
          name: "贵溪政府网",
        },
        {
          name: "鹰潭市图书馆网站",
        },
        {
          name: "江西省图书馆学会网站",
        },
        {
          name: "鹰潭在线",
        },
        {
          name: "江西文化共享工程网站",
        },
        {
          name: "江西省图书馆网站",
        },
        {
          name: "江西大江网",
        },
        {
          name: "江西古籍保护网",
        },
        {
          name: "江西省文化网站",
        },
      ],
      infoList: [
        {
          name: "版权所有：贵溪市图书馆",
        },
        {
          name: "赣ICP备案：赣ICP备17008151号-1",
        },
        {
          name: "地址：贵溪市雄石东路16号",
        },
        {
          name: "联系人：薛建国",
        },
        {
          name: "联系电话：13870192600 0701-7187677",
        },
        {
          name: "邮箱：jxgxtsg@163.com",
        },
        {
          name: "访问次数：178524",
        },
        {
          name: "技术支持：上海鹏轩数据技术有限公司",
        },
      ],
      popoverList: [
        {
          img: img1,
          icon: icon1,
          id: 1,
        },
        {
          img: img2,
          icon: icon2,
          id: 2,
        },
        {
          icon: icon3,
          img: img2,
          id: 3,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped>
.footer {
  width: 100vw;
  background-color: rgb(25, 103, 205);
  .content {
    width: 1100px;
    padding: 10px 0px;
    .topIcon {
      display: flex;
      .text {
        padding: 6px 16px;
        background-color: #fff;
        color: #ae0001;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .icons {
        display: flex;
        margin-left: auto;
      }
    }
    .link {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 70%;
      span {
        width: 30%;
        display: inline-block;
        margin-bottom: 20px;
        &:before {
          content: "";
          width: 4px;
          height: 4px;
          background-color: #fff;
          border-radius: 50%;
          display: inline-block;
          margin-right: 8px;
          position: relative;
          top: -3px;
        }
      }
    }
    .info {
      padding-top: 20px;
      border: 0px;
      width: 100%;
      border-top: 1px solid #fff;
      span {
        &:before {
          content: "";
          width: 0px;
          margin-right: 0px;
        }
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .footer {
      .content {
        width: 100%;
        .link {
          width: 100%;
          span {
            color: #fff;
            width: 47%;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
