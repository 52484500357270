<!-- 部门设置 -->
<template>
  <div class="dep publicPage">
    <div class="content">
      <ititle :text="firstTitle"></ititle>
      <ul>
        <li
          v-for="card in cardList"
          :key="card.name"
          :style="{ backgroundColor: card.color }"
          @click="goPage(card)"
        >
          <img :src="card.src" alt="" />
          <span>{{ card.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import img1 from "../../assets/7-部门设置/1-办公室.png";
import img2 from "../../assets/7-部门设置/2-财务室.png";
import img3 from "../../assets/7-部门设置/3-采编室.png";
import img4 from "../../assets/7-部门设置/4-少儿阅读乐园.png";
import img5 from "../../assets/7-部门设置/5-报刊阅览室.png";
import img6 from "../../assets/7-部门设置/6-图书外借室.png";
import img7 from "../../assets/7-部门设置/7-电子阅览室.png";
import img8 from "../../assets/7-部门设置/8-资料查阅室.png";
import img9 from "../../assets/7-部门设置/9-古籍文献室.png";
import img10 from "../../assets/7-部门设置/10-科普放映室.png";
import img11 from "../../assets/7-部门设置/11-象山书院.png";
import img12 from "../../assets/7-部门设置/12-更多.png";
import { getFirstTitle, getArticle } from "../../utils/commonApi";
import { toSecend, toPage } from "../../utils/util";
export default {
  components: { ititle },
  data() {
    return {
      firstTitle: "",
      cardList: [
        {
          name: "办公室",
          src: img1,
        },
        {
          name: "财务室",
          src: img2,
        },
        {
          name: "采编室",
          src: img3,
        },
        {
          name: "少儿阅览室",
          src: img4,
        },
        {
          name: "报刊阅览室",
          src: img5,
        },
        {
          name: "图书外借室",
          src: img6,
        },
        {
          name: "电子阅览室",
          src: img7,
        },
        {
          name: "资料查阅室",
          src: img8,
        },
        {
          name: "古籍文献室",
          src: img9,
        },
        {
          name: "科普放映室",
          src: img10,
        },
        {
          name: "象山书院",
          src: img11,
        },
        {
          name: "更多>>",
          src: img12,
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    //去往文章详情
    goPage(card) {
      //跳转至二级首页
      let params = {
        firstId: 10,
        secondId: card.name != "更多>>" ? card.id : null,
      };
      this.$router.push({ path: "/secondPage", query: params });
    },
  },
  created() {},
  mounted() {
    getFirstTitle(10, (res) => {
      let children = res.children;
      this.firstTitle = res.title;
      this.cardList.forEach((item, i) => {
        if (children[i]) {
          item.name = children[i].title;
          item.id = children[i].id;
          if (children[i].img) {
            item.src = children[i].img;
          }
        }
      });
    });
  },
};
</script>

<style lang="less" scoped>
.dep {
  .content {
    width: 1100px;
    padding: 10px 0px;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 16%;
        font-size: 16px;
        cursor: pointer;
        background-color: #59a9de;
        margin-bottom: 10px;
        color: #fff;
        height: 230px;
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .dep {
      .content {
        width: auto;
        ul {
          li {
            width: 32%;
            height: 100px;
            img {
              width: 80%;
            }
            span {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
