<!-- 联系我们 -->
<template>
  <div class="callus publicPage">
    <div class="content">
      <ititle :text="firstTitle"></ititle>
      <img src="../../assets/7-部门设置/address.png" alt="" />
      <div class="message">
        <div class="left">
          <div class="itemBox">
            <div class="title">联系地址</div>
            <div class="cont">贵溪市雄石东路16号</div>
          </div>
          <div class="itemBox">
            <div class="title">联系人</div>
            <div class="cont">薛建国</div>
          </div>
          <div class="itemBox">
            <div class="title">联系电话</div>
            <div class="cont">13870192600 0701-7187677</div>
          </div>
          <div class="itemBox">
            <div class="title">联系邮箱</div>
            <div class="cont">jxgxtsg@163.com</div>
          </div>
        </div>
        <div class="right">
          <div class="title">在线留言</div>
          <el-form ref="form" :model="form" label-width="40px">
            <el-form-item label="姓名">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="电话">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
            <el-form-item label="邮箱">
              <el-input v-model="form.email"></el-input>
            </el-form-item>
            <el-form-item label="内容">
              <el-input v-model="form.content"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="questbook">提交留言>></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ititle from "../../components/ititle.vue";
import { questbook } from "../../request/apis";
import { getFirstTitle } from "../../utils/commonApi";
export default {
  components: { ititle },
  data() {
    return {
      firstTitle: "",
      form: {
        name: "",
        phone: "",
        email: "",
        content: "",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    //添加留言
    questbook() {
      questbook(this.form).then((res) => {
        this.$message.success("提交成功！");
      });
    },
  },
  created() {},
  mounted() {
    getFirstTitle(11, (res) => {
      this.firstTitle = res.title;
    });
  },
};
</script>
<style lang="less" scoped>
.callus {
  width: 100vw;
  background-color: #e7e5e6;
  .content {
    width: 1100px;
    padding: 10px 0px;
    img {
      width: 100%;
    }
    .message {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .title {
        font-weight: bold;
        margin: 10px 0px;
        position: relative;
        &:after {
          content: "";
          width: 26px;
          height: 2px;
          background-color: #ae0001;
          position: absolute;
          bottom: -6px;
          left: 0px;
        }
      }
      .left {
        .itemBox {
          padding: 10px 0px;
        }
      }
      .right {
        width: 400px;
      }
    }
  }
}
@media screen {
  @media (max-width: 600px) {
    .callus {
      background-color: #fff;
      .content {
        width: 100%;
        .message {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
